<template>
  <main class="view-key-container">
    <div class="view-key">
      <h1>Server Status</h1>
      <div class="status-banner">
        All Systems Operational
      </div>
      <div class="issue">
        <span class="status green">Resolved</span>
        <p class="tag green">Opened: February 13, 2021 3:04pm EST</p>
        <p class="tag green">Resolved: February 13, 2021 5:30pm EST</p>
        <p>
          Description: Our Mongo Database reached maximum capacity causing new uploads to fail. The solution was to add infrastructure to automatically archive and compress stale data.
        </p>
      </div>
      <div class="issue">
        <span class="status green">Resolved</span>
        <p class="tag green">Opened: January 14, 2021 3:47pm EST</p>
        <p class="tag green">Resolved: January 14, 2021 4:15pm EST</p>
        <p>
          Description: The server disk reached maximum capacity causing new uploads to fail. The solution was to add infrastructure to automatically remove stale files from the disk.
        </p>
      </div>
    </div>
  </main>
</template>
<script lang="ts">
  import Vue from 'vue';
  export default Vue.extend({

  });
</script>
<style scoped lang="less">
  @import '../less/old';
  @import '../less/variables';
  main.view-key-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .view-key {
    text-align: center;
  }
  .status-banner{
    border-radius: 1rem;
    font-weight: 600;
    padding: 0.5rem 0rem;
    width: 600px;
    margin: 1rem 0;
    background: @green;
    text-align: center;
    font-size: 1.5rem;
    box-sizing: border-box;
  }
  .issue{
    box-sizing: border-box;
    width: 600px;
    border-radius: 1rem;
    border:2px solid fade(#fff, 10%);
    padding: 2rem 2rem 1rem 2rem;
    text-align: left;
    margin-bottom: 1.5rem;
    p{
      margin: 0 0 1rem 0;
    }
    .status{
      color: #fff;
      padding: 0.1rem 1rem;
      border-radius: 1.5rem;
      float: right;
      font-weight: 600;
      &.green{
        background: @green;
      }
      &.orange{
        background: @red;
      }
      &.red{
        background: @red;
      }
    }
  }
</style>
