import { render, staticRenderFns } from "./PageStatus.vue?vue&type=template&id=580f58cd&scoped=true"
import script from "./PageStatus.vue?vue&type=script&lang=ts"
export * from "./PageStatus.vue?vue&type=script&lang=ts"
import style0 from "./PageStatus.vue?vue&type=style&index=0&id=580f58cd&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580f58cd",
  null
  
)

export default component.exports